import { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import Busy from '../../Components/Busy';
import { ReactComponent as IconArrow } from '../../assets/images/arrow.svg';
import IconCamera from '../../assets/images/camera.png';
import IconMedical from '../../assets/images/medical.png';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EventContext } from '../../Contexts/Events';
import Modal from '../../Components/Modal';
import { AuthenticationContext } from '../../Contexts/Authentication';

function TimetableView() {
    const [lessonState, setLessonState] = useState({
        busy: true,
        updating: false,
        attendances: [] as any,
        badges: [] as any,
        centres: [] as any,
        levels: [] as any,
        instructors: [] as any,
        criteria: [] as any,
        moveLessons: [] as any,
        medicalModal: false as any,
        moveModal: false as any,
        rescheduleModal: false as any,
        rescheduleInstructorId: false as any,
        rescheduleDate: false as any,
        rescheduleTime: false as any,
        rescheduleTimes: [] as any,
        bulkProgressModal: false as any,
        criteriaModal: false as any,
        moveInstructorId: false as any,
        moveCentreId: false as any,
        moveLevelId: false as any,
        moveDay: false as any,
        moveCourseId: false as any,
        moveCourseLessons: {} as any,
        moveCourseLessonId: false as any,
        moveCourseLessonType: false as any,
    });

    const loading = useRef(true);
    const { date, courseId } = useParams();
    const navigate = useNavigate();
    const eventHandler = useContext(EventContext);
    const authentication = useContext(AuthenticationContext);

    useEffect(() => {
        const attendancesReceived = (attendances: any = undefined) => {
            if (!attendances) {
                navigate('/', {
                    state: {
                        error: {
                            title: 'Error',
                            message: 'The requested lesson could not be loaded.'
                        }
                    }
                });
                return;
            }
            setLessonState(state => ({...state, updating: false, busy: false, attendances}));
        }

        const badgesReceived = (badges: any = undefined) => {
            setLessonState(state => ({...state, updating: false, busy: false, badges}));
        }

        const centresReceived = (centres: any = undefined) => {
            setLessonState(state => ({...state, updating: false, busy: false, centres}));
        }

        const levelsReceived = (levels: any = undefined) => {
            setLessonState(state => ({...state, updating: false, busy: false, levels}));
        }

        const instructorsReceived = (instructors: any = undefined) => {
            setLessonState(state => ({...state, updating: false, busy: false, instructors}));
        }

        const criteriaReceived = (criteria: any = undefined) => {
            setLessonState(state => ({...state, updating: false, busy: false, criteria}));
        }

        const moveLessonsReceived = (moveLessons: any = undefined) => {
            if (moveLessons !== false) {
                setLessonState(state => ({ ...state, updating: false, busy: false, moveLessons: moveLessons.items }));
            } else {
                setLessonState(state => ({ ...state, updating: false, busy: false, moveLessons: [] }));
            }
        }

        const rescheduleTimesReceived = (body: any = undefined) => {
            if (body !== false && 'times' in body && body.times.length > 0) {
                setLessonState(state => ({ ...state, updating: false, busy: false, rescheduleTimes: body.times }));
            } else {
                setLessonState(state => ({ ...state, updating: false, busy: false, rescheduleTimes: [] }));
            }
        }

        if (loading.current) {
            eventHandler.trigger('attendances:request', courseId, date);
            eventHandler.trigger('badges:request');
            eventHandler.trigger('centres:request');
            eventHandler.trigger('levels:request');
            eventHandler.trigger('instructors:request');
            eventHandler.trigger('criteria:request');
            loading.current = false;
        }

        eventHandler.on('badges:receive', badgesReceived);
        eventHandler.on('centres:receive', centresReceived);
        eventHandler.on('levels:receive', levelsReceived);
        eventHandler.on('instructors:receive', instructorsReceived);
        eventHandler.on('attendances:receive:' + courseId, attendancesReceived);
        eventHandler.on('criteria:receive', criteriaReceived);
        eventHandler.on('moveLessons:receive:' + courseId, moveLessonsReceived);
        eventHandler.on('rescheduleTimes:receive:' + courseId, rescheduleTimesReceived);
        return () => {
            eventHandler.off('badges:receive', badgesReceived);
            eventHandler.off('centres:receive', centresReceived);
            eventHandler.off('levels:receive', levelsReceived);
            eventHandler.off('instructors:receive', instructorsReceived);
            eventHandler.off('attendances:receive:' + courseId, attendancesReceived);
            eventHandler.off('criteria:receive', criteriaReceived);
            eventHandler.off('moveLessons:receive:' + courseId, moveLessonsReceived);
            eventHandler.off('rescheduleTimes:receive:' + courseId, rescheduleTimesReceived);
        }
    }, [lessonState, courseId, date, navigate, eventHandler]);

    const toggleStudent = (idx: any) => {
        lessonState.attendances.forEach((a: any, _idx: any) => {
            if (_idx !== idx) {
                a.open = false;
            }
        });
        lessonState.attendances[idx].open = !!!lessonState.attendances[idx].open;
        setLessonState((state) => ({...state, attendances: lessonState.attendances}));
    }

    const toggleCriteria = (idx: any) => {
        Object.keys(lessonState.attendances[lessonState.criteriaModal].criteria).forEach((_idx: any) => {
            if (_idx !== idx) {
                lessonState.attendances[lessonState.criteriaModal].criteria[_idx].open = false;
            }
        });
        lessonState.attendances[lessonState.criteriaModal].criteria[idx].open = !!!lessonState.attendances[lessonState.criteriaModal].criteria[idx].open;
        setLessonState((state) => ({...state, attendances: lessonState.attendances}));
    }

    const updateAttendanceNotes = (idx: any, e: any) => {
        eventHandler.trigger('actions:add', 'lesson', lessonState.attendances[idx].id, {
            lesson_notes: e.target.value
        });
        lessonState.attendances[idx].lesson_notes = e.target.value;
        setLessonState({...lessonState, attendances: lessonState.attendances});
    }

    const updateCourseDiscs = (idx: any, e: any) => {
        eventHandler.trigger('actions:add', 'lesson', lessonState.attendances[idx].id, {
            discs: e.target.value
        });
        lessonState.attendances[idx].discs = e.target.value;
        setLessonState({...lessonState, attendances: lessonState.attendances});
    }

    const updateCourseBadge = (idx: any, e: any) => {
        eventHandler.trigger('actions:add', 'lesson', lessonState.attendances[idx].id, {
            badge_id: e.target.options[e.target.selectedIndex].value
        });
        lessonState.attendances[idx].badge_id = e.target.options[e.target.selectedIndex].value;
        setLessonState({...lessonState, attendances: lessonState.attendances});
    }

    const updateAttendanceStatus = (idx: any, e: any) => {
        eventHandler.trigger('actions:add', 'lesson', lessonState.attendances[idx].id, {
            lesson_status: (e.target.checked ? 'present' : 'absent')
        });
        lessonState.attendances[idx].lesson_status = (e.target.value ? 'present' : 'absent');
        setLessonState({...lessonState, attendances: lessonState.attendances});
    }

    const updateRescheduleTimes = (e: any, key: any) => {
        (lessonState as any)[key] = ('selectedIndex' in e.target ? (e.target.selectedIndex !== -1 ? e.target.options[e.target.selectedIndex].value : null) : (e.target.value ?? null));
        setLessonState({ ...lessonState });
        if (lessonState.rescheduleInstructorId && lessonState.rescheduleDate) {
            eventHandler.trigger('rescheduleTimes:request', courseId, lessonState.attendances[lessonState.rescheduleModal].lesson_id, lessonState);
        }
    }

    const updateCriteriaStatus = (idx: any, e: any) => {
        let changes: any = {};
        changes['criteria.' + lessonState.attendances[lessonState.criteriaModal].criteria[idx].id + '.achieved_at'] = moment().format('YYYY-MM-DD HH:mm:ss');
        eventHandler.trigger('actions:add', 'lesson', lessonState.attendances[lessonState.criteriaModal].id, changes);
        lessonState.attendances[lessonState.criteriaModal].criteria[idx].achieved_at = moment().format('YYYY-MM-DD HH:mm:ss');
        setLessonState({...lessonState, attendances: lessonState.attendances});
    }

    const updateBulkCriteriaStatus = (attendanceId: any, idx: any, e: any) => {
        let changes: any = {};
        changes['criteria.' + lessonState.attendances[attendanceId].criteria[idx].id + '.achieved_at'] = moment().format('YYYY-MM-DD HH:mm:ss');
        eventHandler.trigger('actions:add', 'lesson', lessonState.attendances[attendanceId].id, changes);
        lessonState.attendances[attendanceId].criteria[idx].achieved_at = moment().format('YYYY-MM-DD HH:mm:ss');
        setLessonState({...lessonState, attendances: lessonState.attendances});
    }

    const updateCriteriaNotes = (idx: any, e: any) => {
        let changes: any = {};
        changes['criteria.' + lessonState.attendances[lessonState.criteriaModal].criteria[idx].id + '.notes'] = e.target.value;
        eventHandler.trigger('actions:add', 'lesson', lessonState.attendances[lessonState.criteriaModal].id, changes);
        lessonState.attendances[lessonState.criteriaModal].criteria[idx].notes = e.target.value;
        setLessonState({...lessonState, attendances: lessonState.attendances});
    }

    const updateCourseLessons = (e: any, key: any) => {
        (lessonState as any)[key] = (e.target.selectedIndex !== -1 ? e.target.options[e.target.selectedIndex].value : null);
        setLessonState({ ...lessonState });
        eventHandler.trigger('moveLessons:request', courseId, lessonState.attendances[lessonState.moveModal].lesson_id, lessonState);
    }

    const moveLesson = (e: any) => {
        e.preventDefault();
        eventHandler.trigger('moveLesson', lessonState.attendances[lessonState.moveModal].id, lessonState.moveCourseLessonId, lessonState.moveCourseLessonType);
        navigate('/', {
            state: {
                error: {
                    title: 'Student Moved',
                    message: 'The student has been successfully moved.'
                }
            }
        });
    }

    const rescheduleLesson = (e: any) => {
        e.preventDefault();
        eventHandler.trigger('rescheduleLesson', lessonState.attendances[lessonState.rescheduleModal].lesson_id, lessonState.rescheduleInstructorId, lessonState.rescheduleDate, lessonState.rescheduleTime);
        navigate('/', {
            state: {
                error: {
                    title: 'Lesson Rescheduled',
                    message: 'The lesson has been successfully rescheduled.'
                }
            }
        });
    }

    let badge_id: any = null;

    return (
        <div className={`LessonView ${lessonState.busy ? 'is--busy' : ''}`}>
            {lessonState.busy && (
                <Busy />
            )}
            {!lessonState.busy && (
                <>
                    {lessonState.medicalModal !== false && (
                        <Modal onDismiss={() => setLessonState({ ...lessonState, medicalModal: false })} title="Medical Notes">{lessonState.medicalModal}</Modal>
                    )}
                    {lessonState.criteriaModal !== false && (
                        <div className="CriteriaModal">
                            <h3>Level/Badge Progress</h3>
                            <h4>{lessonState.attendances[lessonState.criteriaModal].first_name} {lessonState.attendances[lessonState.criteriaModal].last_name} <span>- {lessonState.attendances[lessonState.criteriaModal].level}</span></h4>
                            {Object.keys(lessonState.attendances[lessonState.criteriaModal].criteria).map((idx: any) => {
                                const criteria = lessonState.attendances[lessonState.criteriaModal].criteria[idx];
                                return (
                                    <div className={'LessonCriteriaRow' + (criteria.open ? ' open' : '')} key={idx}>
                                        <div className="LessonCriteriaRowHeader">
                                            <div className="vc-toggle-container">
                                                <label className="vc-switch">
                                                    <input type="checkbox" onChange={(e) => updateCriteriaStatus(idx, e)} defaultChecked={!!criteria.achieved_at} className="vc-switch-input" />
                                                    <span
                                                        data-on="Done"
                                                        data-off="To do"
                                                        className="vc-switch-label"
                                                    ></span>
                                                    <span className="vc-handle"></span>
                                                </label>
                                            </div>
                                            <div className="name">{criteria.criteria}</div>
                                            <button type="button" onClick={(e) => toggleCriteria(idx)} className="toggle">
                                                <IconArrow width={6} height={10} />
                                            </button>
                                        </div>
                                        <div className="LessonCriteriaRowBody">
                                            <textarea placeholder="Criteria Notes" rows={6} onBlur={(e) => updateCriteriaNotes(idx, e)} defaultValue={criteria.notes}></textarea>
                                        </div>
                                    </div>
                                );
                            })}
                            <button type="button" className="Back" onClick={(e) => setLessonState({...lessonState, criteriaModal: false})}>
                                <span>
                                    <IconArrow width={15.91} height={24.6} />
                                </span>
                                Back to Lesson
                            </button>
                        </div>
                    )}
                    {lessonState.moveModal !== false && (
                        <div className="MoveModal">
                            <h3>Move Course</h3>
                            {authentication.offline() && (
                                <div className="error">This feature can only be used when connected to the internet.</div>
                            )}
                            <dl>
                                <dt>Course</dt>
                                <dd>{lessonState.attendances[lessonState.moveModal].course}</dd>
                                <dt>Parent</dt>
                                <dd>{lessonState.attendances[lessonState.moveModal].parent}</dd>
                                {'telephone' in lessonState.attendances[lessonState.moveModal] && lessonState.attendances[lessonState.moveModal].telephone.length > 0 && (
                                    <>
                                        <dt>Telephone</dt>
                                        <dd><a href="tel:{attendance.telephone}">{lessonState.attendances[lessonState.moveModal].telephone}</a></dd>
                                    </>
                                )}
                                <dt>Level</dt>
                                <dd>{lessonState.attendances[lessonState.moveModal].level ?? 'Not specified'}</dd>
                                <dt>Photography</dt>
                                <dd>{lessonState.attendances[lessonState.moveModal].photography === '0' ? 'No' : 'Yes'}</dd>
                                <dt>Medical</dt>
                                <dd>{lessonState.attendances[lessonState.moveModal].notes === '' ? 'No' :
                                    <>Yes - <a onClick={(e) => setLessonState({...lessonState, medicalModal: lessonState.attendances[lessonState.moveModal].notes})}>View Notes</a></>
                                }</dd>
                            </dl>

                            <hr />

                            <form onSubmit={moveLesson}>
                                <p><strong>Select course</strong></p>
                                <input type="hidden" name="student_id" />
                                <input type="hidden" name="course_lesson_attendance_id" />
                                <select name="instructor_id" onChange={(e) => updateCourseLessons(e, 'moveInstructorId')}>
                                    <option key={0} value="">Select an Instructor</option>
                                    {lessonState.instructors.map((instructor: any, idx: any) => {
                                        return <option key={idx} value={instructor.id}>{instructor.name}</option>;
                                    })}
                                </select>
                                <select name="centre_id" onChange={(e) => updateCourseLessons(e, 'moveCentreId')}>
                                    <option key={0} value="">Select a Centre</option>
                                    {lessonState.centres.map((centre: any, idx: any) => {
                                        return <option key={idx} value={centre.id}>{centre.name}</option>;
                                    })}
                                </select>
                                <select name="level_id" onChange={(e) => updateCourseLessons(e, 'moveLevelId')}>
                                    <option key={0} value="">Select a Level</option>
                                    {lessonState.levels.map((level: any, idx: any) => {
                                        return <option key={idx} value={level.id}>{level.name}</option>;
                                    })}
                                </select>
                                <select name="day" onChange={(e) => updateCourseLessons(e, 'moveDay')}>
                                    <option key={0} value="">Select a Day</option>
                                    <option key={2} value="2">Monday</option>
                                    <option key={3} value="3">Tuesday</option>
                                    <option key={4} value="4">Wednesday</option>
                                    <option key={5} value="5">Thursday</option>
                                    <option key={6} value="6">Friday</option>
                                    <option key={7} value="7">Saturday</option>
                                    <option key={1} value="1">Sunday</option>
                                </select>
                                <select name="course_id" onChange={(e) => setLessonState({ ...lessonState, moveCourseLessonType: 'group', moveCourseId: (e.target.selectedIndex !== -1 ? e.target.options[e.target.selectedIndex].value : false), moveCourseLessons: (e.target.selectedIndex !== -1 && e.target.options[e.target.selectedIndex].getAttribute('data-key') ? lessonState.moveLessons[e.target.options[e.target.selectedIndex].getAttribute('data-key') as any].lessons : {})})} disabled={lessonState.moveLessons.length === 0}>
                                    <option value="">Select a Course</option>
                                    {lessonState.moveLessons.length > 0 && (
                                        <>
                                            {lessonState.moveLessons.map((item: any, idx: any) => {
                                                return <option key={idx} data-key={idx} value={item.value}>{item.label}</option>;
                                            })}
                                        </>
                                    )}
                                </select>
                                <select name="course_lesson_id" onChange={(e) => setLessonState({...lessonState, moveCourseLessonId: (e.target.selectedIndex !== -1 ? e.target.options[e.target.selectedIndex].value : false)})} disabled={Object.keys(lessonState.moveCourseLessons).length === 0}>
                                    <option value="">Select the starting lesson</option>
                                    {Object.keys(lessonState.moveCourseLessons).length > 0 && (
                                        <>
                                            {Object.keys(lessonState.moveCourseLessons).map((key: any, idx: any) => {
                                                const item = lessonState.moveCourseLessons[key];
                                                return <option key={idx} value={key}>{item}</option>;
                                            })}
                                        </>
                                    )}
                                </select>
                                <button disabled={authentication.offline() || lessonState.moveCourseLessonId === false}>Move</button>
                            </form>

                            <hr />

                            <button type="button" className="Back" onClick={(e) => setLessonState({...lessonState, moveModal: false})}>
                                <span>
                                    <IconArrow width={15.91} height={24.6} />
                                </span>
                                Back to Lesson
                            </button>
                        </div>
                    )}
                    {lessonState.rescheduleModal !== false && (
                        <div className="RescheduleModal">
                            <h3>Reschedule Lesson</h3>
                            {authentication.offline() && (
                                <div className="error">This feature can only be used when connected to the internet.</div>
                            )}
                            <dl>
                                <dt>Course</dt>
                                <dd>{lessonState.attendances[lessonState.rescheduleModal].course}</dd>
                                <dt>Parent</dt>
                                <dd>{lessonState.attendances[lessonState.rescheduleModal].parent}</dd>
                                <dt>Date/Time</dt>
                                <dd>{moment(lessonState.attendances[lessonState.rescheduleModal].lesson_date).format('DD.MM.YYYY h:mma')}</dd>
                                {'telephone' in lessonState.attendances[lessonState.rescheduleModal] && lessonState.attendances[lessonState.rescheduleModal].telephone.length > 0 && (
                                    <>
                                        <dt>Telephone</dt>
                                        <dd><a href="tel:{attendance.telephone}">{lessonState.attendances[lessonState.rescheduleModal].telephone}</a></dd>
                                    </>
                                )}
                                <dt>Level</dt>
                                <dd>{lessonState.attendances[lessonState.rescheduleModal].level ?? 'Not specified'}</dd>
                                <dt>Photography</dt>
                                <dd>{lessonState.attendances[lessonState.rescheduleModal].photography === '0' ? 'No' : 'Yes'}</dd>
                                <dt>Medical</dt>
                                <dd>{lessonState.attendances[lessonState.rescheduleModal].notes === '' ? 'No' :
                                    <>Yes - <a onClick={(e) => setLessonState({...lessonState, medicalModal: lessonState.attendances[lessonState.rescheduleModal].notes})}>View Notes</a></>
                                }</dd>
                            </dl>

                            <hr />

                            <form onSubmit={rescheduleLesson}>
                                <p><strong>Select date/time</strong></p>
                                <input type="hidden" name="student_id" />
                                <input type="hidden" name="lesson_id" />
                                <select name="instructor_id" onChange={(e) => updateRescheduleTimes(e, 'rescheduleInstructorId')}>
                                    <option key={0} value="">Select an Instructor</option>
                                    {lessonState.instructors.map((instructor: any, idx: any) => {
                                        return <option key={idx} value={instructor.id}>{instructor.name}</option>;
                                    })}
                                </select>
                                <input type="date" name="date" placeholder="Select a date" onChange={(e) => updateRescheduleTimes(e, 'rescheduleDate')} />
                                <select name="time" onChange={(e) => updateRescheduleTimes(e, 'rescheduleTime')} disabled={lessonState.rescheduleTimes.length === 0}>
                                    <option value="">Select a time</option>
                                    {lessonState.rescheduleTimes.map((time: any, idx: any) => {
                                        return <option value={time} key={idx}>{time}</option>
                                    })}
                                </select>
                                <button disabled={authentication.offline() || lessonState.rescheduleInstructorId === false || lessonState.rescheduleDate === false || lessonState.rescheduleTime === false}>Reschedule</button>
                            </form>

                            <hr />

                            <button type="button" className="Back" onClick={(e) => setLessonState({...lessonState, rescheduleModal: false})}>
                                <span>
                                    <IconArrow width={15.91} height={24.6} />
                                </span>
                                Back to Lesson
                            </button>
                        </div>
                    )}
                    {lessonState.bulkProgressModal !== false && (
                        <div className="BulkProgressModal">
                            <h3>Update Criteria</h3>
                            <p><strong>{lessonState.attendances[0].course_level}</strong></p>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="criteria">Criteria</th>
                                            <th>&nbsp;</th>
                                            {lessonState.attendances.map((attendance: any, idx: any) => {
                                                return <th key={idx}>{attendance.first_name} {attendance.last_name}</th>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lessonState.criteria.filter((c: any) => c.level_id == lessonState.attendances[0].course_level_id).map((criteria: any, cidx: any) => {
                                            let criteriaId = Number.parseInt(criteria.id),
                                                row = [<tr key={cidx}>
                                                    <td>{criteria.criteria}</td>
                                                    <td>&nbsp;</td>
                                                    {lessonState.attendances.map((attendance: any, idx: any) => {
                                                        return <td key={idx}><input type="checkbox" onChange={(e) => updateBulkCriteriaStatus(idx, criteria.id, e)} defaultChecked={criteriaId in attendance.criteria && attendance.criteria[criteriaId].achieved_at ? true : false} /></td>
                                                    })}
                                                </tr>];
                                            if (badge_id != criteria.badge_id) {
                                                badge_id = criteria.badge_id;
                                                row.unshift(<tr key={'b' + cidx}>
                                                    <th className="badge" colSpan={lessonState.attendances.length + 2}>{lessonState.badges.length > 0 ? lessonState.badges.reduce((acc: any, item: any) => { if (item.id == badge_id) { acc = item.name; } return acc; }, '') : ''}</th>
                                                </tr>);
                                            }
                                            return row;
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <button type="button" className="Back" onClick={(e) => setLessonState({...lessonState, bulkProgressModal: false})}>
                                <span>
                                    <IconArrow width={15.91} height={24.6} />
                                </span>
                                Back to Lesson
                            </button>
                        </div>
                    )}
                    {lessonState.attendances.length > 0 && (
                        <div className="LessonViewDetail">
                            <h3>{lessonState.attendances[0].course}</h3>
                            {lessonState.attendances.map((attendance: any, idx: any) => {
                                return (
                                    <div className={'LessonAttendanceRow' + (attendance.open ? ' open' : '')} key={idx}>
                                        <div className="LessonAttendanceRowHeader">
                                            <div className="vc-toggle-container">
                                                <label className="vc-switch">
                                                    <input type="checkbox" onChange={(e) => updateAttendanceStatus(idx, e)} defaultChecked={attendance.lesson_status === 'present'} className="vc-switch-input" />
                                                    <span
                                                        data-on="Present"
                                                        data-off="Absent"
                                                        className="vc-switch-label"
                                                    ></span>
                                                    <span className="vc-handle"></span>
                                                </label>
                                            </div>
                                            <div className={`name ${attendance.paid_at ? '' : 'unpaid'}`}>{attendance.first_name} {attendance.last_name} {attendance.last_badge && (
                                                <> ({attendance.last_badge})</>
                                            )}</div>
                                            {attendance.using_discs && (
                                                <span className="discs">{attendance.discs}</span>
                                            )}
                                            {attendance.photography === '0' && (
                                                <img src={ IconCamera } width="20" height="18" alt="" />
                                            )}
                                            {attendance.notes !== '' && (
                                                <img src={ IconMedical } width="18" height="19" alt="" />
                                            )}
                                            <button type="button" onClick={(e) => toggleStudent(idx)} className="toggle">
                                                <IconArrow width={6} height={10} />
                                            </button>
                                        </div>
                                        <div className="LessonAttendanceRowBody">
                                            <dl>
                                                <dt>Parent</dt>
                                                <dd>{attendance.parent}</dd>
                                                {'telephone' in attendance && attendance.telephone.length > 0 && (
                                                    <>
                                                        <dt>Telephone</dt>
                                                        <dd><a href="tel:{attendance.telephone}">{attendance.telephone}</a></dd>
                                                    </>
                                                )}
                                                <dt>Level</dt>
                                                <dd>{attendance.level ?? 'Not specified'}</dd>
                                                <dt>Photography</dt>
                                                <dd>{attendance.photography === '0' ? 'No' : 'Yes'}</dd>
                                                <dt>Medical</dt>
                                                <dd>{attendance.notes === '' ? 'No' :
                                                    <>Yes - <a onClick={(e) => setLessonState({...lessonState, medicalModal: attendance.notes})}>View Notes</a></>
                                                }</dd>
                                            </dl>
                                            {Number.parseInt(attendance.awarded_price_applied) > 0 && (
                                                <>
                                                    <label>Badge</label>
                                                    <select onChange={(e) => updateCourseBadge(idx, e)} value={Number.parseInt(attendance.badge_id || '0')}>
                                                        <option value="">None</option>
                                                        {lessonState.badges.filter((b: any) => Number.parseInt(b.level_id) === Number.parseInt(attendance.level_id)).map((b: any, _: any) => {
                                                            return <option value={Number.parseInt(b.id)} key={_}>{b.name}</option>
                                                        })}
                                                    </select>
                                                </>
                                            )}
                                            {attendance.using_discs && (
                                                <>
                                                    <label>Discs</label>
                                                    <input type="text" onBlur={(e) => updateCourseDiscs(idx, e)} defaultValue={attendance.discs} />
                                                </>
                                            )}
                                            <label>Lesson Notes</label>
                                            <textarea placeholder="Lesson Notes" rows={6} onBlur={(e) => updateAttendanceNotes(idx, e)} defaultValue={attendance.lesson_notes}></textarea>
                                            <button type="button" onClick={(e) => setLessonState({...lessonState, criteriaModal: idx})}>Level/Badge Progress</button>
                                            {!authentication.offline() && <button type="button" onClick={(e) => setLessonState({ ...lessonState, moveModal: idx })}>Move Course</button>}
                                            {!authentication.offline() && attendance.type === 'single' && <button type="button" onClick={(e) => setLessonState({ ...lessonState, rescheduleModal: idx })}>Reschedule Lesson</button>}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <button id="bulk-progress" onClick={(e) => setLessonState({...lessonState, bulkProgressModal: true})}>Class Level/Badge Progress</button>
                    <Link className="Back" to="/">
                        <span>
                            <IconArrow width={15.91} height={24.6} />
                        </span>
                        Back to Lessons
                    </Link>
                </>
            )}

        </div>
    );
}

export default TimetableView;
