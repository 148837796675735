import Authentication from "../../Contexts/Authentication";
import { NavigateFunction } from 'react-router-dom';


export const urlBase64ToUint8Array = (base64String: string) => {
    let padding = '='.repeat((4 - base64String.length % 4) % 4);
    let base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    let rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export default class ApiRequest {
    api_base: string = process.env.REACT_APP_API_URL || 'http://swim-excellence.test/wp-json/tvw/v1';
    authentication: Authentication;
    navigate: any;
    errorHandler: any;
    lastHeaders: any[] = []

    constructor(authentication: Authentication) {
        this.authentication = authentication;
    }

    registerNavigate(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    registerErrorHandler(cb: any) {
        this.errorHandler = cb;
    }

    handleError(e: any) {
        if (typeof this.errorHandler === 'function') {
            this.errorHandler(e);
        } else {
            if ('status' in e && e.status === 401 && this.navigate) {
                this.authentication.logout();
                this.navigate('/login');
                return;
            }
            console.error(e);
        }
        throw new Error('Unhandled response');
    }

    async handleResponse(response: Response) {
        if (response && [200, 201, 202, 204].indexOf(response.status) !== -1) return await response.json();
        if (response && [401].indexOf(response.status) !== -1) {
            this.authentication.logout();
            this.navigate('/login');
            return;
        }
        return this.handleError(new Error('Unhandled response'));
    }

    prepareRequest(params: any = {}) {
        if (!('headers' in params)) {
            params.headers = {};
        }
        params.headers['Content-Type'] = 'application/json';
        params.headers['Accept'] = 'application/json';
        params.withCredentials = true;
        params.redirect = 'manual';

        if (this.authentication.check()) {
            params.headers['Authorization'] = 'Bearer ' + this.authentication.token();
        }

        return params;
    }

    prepareBody(body: any) {
        if (body instanceof FormData) {
            body = Object.fromEntries(body);
        }
        return JSON.stringify(body);
    }

    async get(url: string, timeout: number = 5000) {
        const response = await fetch(this.api_base + url, this.prepareRequest({
            method: 'get',
            signal: AbortSignal.timeout(timeout)
        }));

        if (response.ok) {
            return this.handleResponse(response);
        }
        return this.handleError(response);
    }

    async post(url: string, body: object, timeout: number = 5000) {
        const response = await fetch(this.api_base + url, this.prepareRequest({
            method: 'post',
            signal: AbortSignal.timeout(timeout),
            body: this.prepareBody(body)
        }));

        if (response.ok) {
            return this.handleResponse(response);
        }
        this.handleError(response);
    }

    async put(url: string, body: object) {
        const response = await fetch(this.api_base + url, this.prepareRequest({
            method: 'put',
            signal: AbortSignal.timeout(5000),
            body: this.prepareBody(body)
        }));

        if (response.ok) {
            return this.handleResponse(response);
        }
        return this.handleError(response);
    }
}
