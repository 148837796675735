import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../../Contexts/Authentication';
import Logo from '../../assets/images/logo-1.png';
import { EventContext } from '../../Contexts/Events';
import { DataContext } from '../../Contexts/Data';


function Private() {
  const authentication = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const eventHandler = useContext(EventContext);
  const location = useLocation();

  const dataLayer = useContext(DataContext);
  dataLayer.getApi().registerNavigate(navigate);

  const [online, setOnline] = useState(!authentication.offline());

  useEffect(() => {
    if (!authentication.check()) {
      navigate('/login');
      return;
    }
  }, [authentication, navigate, eventHandler, dataLayer]);

  const onlineUpdated = useCallback(() => {
    setOnline(navigator.onLine);
    eventHandler.trigger('notifications:request');
  }, [eventHandler]);

  useEffect(() => {
    const handleNetworkChange = () => {
      setOnline(navigator.onLine);
      eventHandler.trigger('notifications:push-check');
    }

    eventHandler.on('network:change', handleNetworkChange);
    return () => {
      eventHandler.off('network:change', handleNetworkChange);
    }
  }, [onlineUpdated, eventHandler]);

  const [debugState, setDebugState] = useState(0);

  const detectDebug = (e: any) => {
    if (debugState === 0) {
      setTimeout(() => {
        setDebugState(0);
      }, 5000);
    }
    setDebugState((value: any) => value + 1);
    if (debugState >= 7) {
      e.preventDefault();
      setDebugState(0);
      navigate('/debug');
    }
  }

  const logout = () => {
    eventHandler.trigger('logout');
    navigate('/login');
  }

  const offlineChanged = (e: any) => {
    authentication.setOffline(!e.target.checked);
    if (e.target.checked) {
      eventHandler.trigger('actions:sync');
    }
  }

  return (
    <div className="PrivateLayout" onClick={detectDebug}>
      <header>
        <Link to="/">
          <img src={Logo} className="PrivateLogo" alt="Swim Excellence" width="185" height="62" />
        </Link>
        {!authentication.offline() && (
          <button type="button" onClick={(e) => eventHandler.trigger('actions:sync')}>Sync</button>
        )}
        <div className="vc-toggle-container">
            <label className="vc-switch">
                <input type="checkbox" onChange={(e) => offlineChanged(e)} checked={!authentication.offline()} className="vc-switch-input" />
                <span
                    data-on="Online"
                    data-off="Offline"
                    className="vc-switch-label"
                ></span>
                <span className="vc-handle"></span>
          </label>
        </div>
        <button type="button" onClick={logout}>Logout</button>
      </header>
      <main>
        {authentication.offline() && (
          <div className="Offline">You are currently offline.<br />Data will be synchronised once reconnected.</div>
        )}
        <Outlet />
      </main>
    </div>
  );
}

export default Private;
