import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import Busy from '../../Components/Busy';
import { ReactComponent as IconArrow } from '../../assets/images/arrow.svg';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../Components/Modal';
import { EventContext } from '../../Contexts/Events';

function TimetableList() {

    const navigate = useNavigate();
    const loading = useRef(true);
    const location = useLocation();
    const eventHandler = useContext(EventContext);

    const [lessonState, setLessonState] = useState({
        busy: true,
        error: false as any,
        lessons: [] as any,
        pageKey: moment().format('YYYY-MM-DD'),
        totalPages: 0,
        ...location.state
    });

    const formatLessons = (lessons: any) => {
        let output: any = {},
            today: any = moment().format('YYYY-MM-DD');
        lessons.forEach((lesson: any) => {
            const date = moment(lesson.lesson_date).format('YYYY-MM-DD');
            // if (date.format('YYYY-MM-DD') !== today) {
            //     return true;
            // }
            if (!(date in output)) {
                output[date] = {};
            }
            if (!(lesson.centre in output[date])) {
                output[date][lesson.centre] = {};
            }
            if (!(lesson.course_id in output[date][lesson.centre])) {
                output[date][lesson.centre][lesson.course_id] = {
                    id: lesson.course_id,
                    name: lesson.course,
                    instructor: lesson.instructor,
                    date: lesson.lesson_date,
                    attendance: []
                };
            }
            output[date][lesson.centre][lesson.course_id].attendance.push(lesson);
        });
        for (let d in output) {
            for (let c in output[d]) {
                let courses = Object.values(output[d][c]);
                courses = courses.sort((a: any, b: any) => {
                    let a_d: any = moment(a.date),
                        b_d: any = moment(b.date);
                    return a_d.diff(b_d);
                });
                output[d][c] = courses;
            }
        }
        return output;
    }

    const showLesson = (lesson: any, date: any) => {
        return (e: any) => {
            navigate('/lessons/view/' + date + '/' + lesson.id);
        };
    }

    const requestLessons = (): Promise<void> => {
        setLessonState({ ...lessonState, busy: true });
        eventHandler.trigger('lessons:request');
        return Promise.resolve();
    };

    useEffect(() => {
        const receiveLessons = (lessons: any) => {
            lessons = formatLessons(lessons);
            setLessonState({ ...lessonState, busy: false, lessons, page: 0, totalPages: Object.keys(lessons).length, pageKey: (Object.keys(lessons).length ? Object.keys(lessons)[0] : false) });
        }
        const actionsSync = () => {
            setLessonState({ ...lessonState, busy: true });
        }
        if (loading.current) {
            eventHandler.trigger('actions:sync');
            loading.current = false;
        }
        eventHandler.on('lessons:receive', receiveLessons);
        eventHandler.on('actions:sync', actionsSync);
        return () => {
            eventHandler.off('lessons:receive', receiveLessons);
            eventHandler.off('actions:sync', actionsSync);
        };
    }, [eventHandler, lessonState]);

    const moveNext = () => {
        setLessonState((state: any) => ({ ...state, page: state.page + 1, pageKey: Object.keys(state.lessons)[state.page + 1] }));
    }

    const movePrevious = () => {
        setLessonState((state: any) => ({ ...state, page: state.page - 1, pageKey: Object.keys(state.lessons)[state.page - 1] }));
    }

    const [startPoint, setStartPoint] = useState(0);
    const [pullChange, setPullChange] = useState(0);

    const pullStart = (e: any) => {
        const { screenY } = e.targetTouches[0];
        setStartPoint(screenY);
    }

    const pull = (e: any) => {
        const touch = e.targetTouches[0];
        const { screenY } = touch;
        setPullChange(startPoint < screenY ? Math.abs(screenY - startPoint) : 0);
    }

    const endPull = (e: any) => {
        setStartPoint(0);
        setPullChange(0);
        if (pullChange > 220) requestLessons();
    }

    useEffect(() => {
        window.addEventListener('touchstart', pullStart);
        window.addEventListener('touchmove', pull);
        window.addEventListener('touchend', endPull);
        return () => {
            window.removeEventListener('touchstart', pullStart);
            window.removeEventListener('touchmove', pull);
            window.removeEventListener('touchend', endPull);
        };
    });

    return (
        <div className={`Lessons ${(lessonState.busy ? 'is--busy' : '')}`}>
            {lessonState.error !== false && (
                <Modal onDismiss={() => setLessonState({ ...lessonState, error: false })} title={lessonState.error.title}>{lessonState.error.message}</Modal>
            )}
            <h1><span dangerouslySetInnerHTML={{ __html: (lessonState.pageKey === moment().format('YYYY-MM-DD') ? 'Today&#39;s' : moment(lessonState.pageKey, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' - ') }}></span> Lessons</h1>
            <div className={'LessonsList' + (lessonState.busy ? ' busy' : '')} style={{ marginTop: pullChange / 3.118 || "" }}>
                {lessonState.busy && (
                    <div className="LessonsListBusy">
                        <Busy />
                    </div>
                )}
                {Object.keys(lessonState.lessons).length === 0 || Object.keys(lessonState.lessons[lessonState.pageKey]).length === 0 && !lessonState.busy && (
                    <div className="LessonsList--Empty">You have not been assigned any lessons.</div>
                )}
                {Object.keys(lessonState.lessons).length > 0 && Object.keys(lessonState.lessons[lessonState.pageKey]).length > 0 && Object.keys(lessonState.lessons[lessonState.pageKey]).map((centre: any) => {
                    return (
                        <React.Fragment key={centre}>
                            <h3>{centre}</h3>
                            {Object.keys(lessonState.lessons[lessonState.pageKey][centre]).map((course_id: any) => {
                                const course = lessonState.lessons[lessonState.pageKey][centre][course_id],
                                    time = moment(course.date, 'YYYY-MM-DD HH:mm:ss').format('h:mma');
                                return (
                                    <button key={course_id} onClick={showLesson(course, lessonState.pageKey)} className="LessonsListItem">
                                        <div><span className="time">{time}</span> <span className="badge">{course.name}</span> <span className="instructor">{course.instructor}</span></div>
                                        <span><IconArrow width={6} height={10} /></span>
                                    </button>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
                {Object.keys(lessonState.lessons).length > 1 && (
                    <div className="LessonsPagination">
                        {lessonState.page > 0 && (
                            <button type="button" disabled={lessonState.busy} onClick={movePrevious} className="Previous">
                                <IconArrow width={15.91} height={24.6} />
                                Previous Day
                            </button>
                        )}
                        {lessonState.page < lessonState.totalPages - 1 && (
                            <button type="button" disabled={lessonState.busy} onClick={moveNext} className="Next">
                                Next Day
                                <IconArrow width={15.91} height={24.6} />
                            </button>
                        )}
                    </div>
                )}
            </div>

        </div>
    );
}

export default TimetableList;
