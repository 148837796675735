import { createContext } from "react";

export default class Authentication {
    token_key: string = 'se-token';
    expiration_key: string = 'se-token-expiration';
    user_id_key: string = 'se-user_id';
    offline_key: string = 'se-offline';

    token() {
        return localStorage.getItem(this.token_key);
    }

    expires() {
        const expires: any = localStorage.getItem(this.expiration_key);
        if (expires) {
            return expires * 1000;
        }
        return Date.now();
    }

    check() {
        return this.token() !== null && this.expires() > Date.now();
    }

    logout() {
        localStorage.removeItem(this.token_key);
        localStorage.removeItem(this.expiration_key);
        localStorage.removeItem(this.user_id_key);
    }

    login(token: string, expires: number) {
        localStorage.setItem(this.token_key, token);
        localStorage.setItem(this.expiration_key, expires.toString());
    }

    userId() {
        return Number.parseInt(localStorage.getItem(this.user_id_key) as string);
    }

    setUserId(user_id: any) {
        localStorage.setItem(this.user_id_key, user_id);
    }

    offline() {
        return !navigator.onLine || localStorage.getItem(this.offline_key) == 'true';
    }

    setOffline(value: any) {
        localStorage.setItem(this.offline_key, value);
    }

}


export const AuthenticationContext = createContext(null as any);
